import React, { useState } from "react";
import AsyncSelect from 'react-select/async';
import ShipProvider from "../../utils/providers/shipProvider";
import LocationsProvider from "../../utils/providers/locationsProvider";
import { customStyles } from "../../utils/selectStyles";
import { CargoAdder } from "./CargoAdder";
import { ShipDescriptorVM } from "../../viewModels/ShipDescriptorVM";
import { CargoWrapper } from "../../types/CargoWrapper";
import { TradeRunVM } from "../../viewModels/TradeRunVM";
import { OnloadVM } from "../../viewModels/OnloadVM";
import ApiClient from "../../utils/client/ApiClient";
import { Formik, Form, Field } from "formik";
import { FormControl, FormLabel, HStack, Button, Box, Heading, useToast } from "@chakra-ui/react";

type NewTradeProps = {
  updateTrades: any
}

export function NewTrade(props: NewTradeProps) {
  const [ship, setShip] = useState<number>(0);
  const [location, setLocation] = useState<number>(0);
  const [locationFilter, setLocationFilter] = useState('');
  const [shipFilter, setShipFilter] = useState('');
  const [shipDesc, setShipDesc] = useState<ShipDescriptorVM>();
  const [cargo, setCargo] = useState<CargoWrapper[]>([]);
  const [isEnabled, setIsEnabled] = useState(true);
  const toast = useToast()

  const handleShipChange = async (selected: any) => {
    if (selected.value === "ph") return;
    setShip(selected.value);
    setShipDesc(await ShipProvider.GetShipDescriptor(selected.value))
  }

  const handleLocationChange = (selected: any) => {
    if (selected.value === "ph") return;
    setLocation(selected.value);
  }

  const loadLocationOptions = async () => await LocationsProvider.GetLocationsDropdownData(locationFilter)

  const loadShipOptions = async () => await ShipProvider.GetCargoShipsDropdownData(shipFilter);

  const toggleSubmit = (isEnabled: boolean) => setIsEnabled(isEnabled);

  return (
    <Box className='new-trade-wrapper'>
      <Formik
        initialValues={{
          ship: '',
          location: '',
          cargo: []
        }}
        onSubmit={async (values, actions) => {
          let newTrade = new TradeRunVM();
          newTrade.ShipId = ship!;
          newTrade.SourceId = location!.toString();
          newTrade.Onloads = [];
          for (let c of cargo.filter(ci => ci.commodityId !== 0)) {
            let newOnload = new OnloadVM();
            newOnload.CommodityId = c.commodityId;
            newOnload.Amount = c.amount;
            newOnload.Price = c.value;
            newTrade.Onloads.push(newOnload);
          }

          let result = await ApiClient.CreateTrade(newTrade);

          toast({
            title: result.message,
            status: result.success ? 'success' : 'error',
            duration: 9000,
            isClosable: true,
          })

          actions.setSubmitting(false)

          props.updateTrades();
        }}
      >
        {(props) => (
          <Form className='new-trade-form'>
            <HStack>
              <Field name="ship">
                {(field: any, form: any) => (
                  <FormControl isRequired>
                    <FormLabel htmlFor="ship">Ship</FormLabel>
                    <AsyncSelect
                      id='ship'
                      styles={customStyles}
                      loadOptions={loadShipOptions}
                      onChange={handleShipChange}
                      onInputChange={setShipFilter}
                      isMulti={false}
                      cacheOptions
                      defaultOptions
                    />
                  </FormControl>
                )}
              </Field>
              <Field name="location">
                {(field: any, form: any) => (
                  <FormControl isRequired>
                    <FormLabel htmlFor="location">Starting location</FormLabel>
                    <AsyncSelect
                      styles={customStyles}
                      loadOptions={loadLocationOptions}
                      onChange={handleLocationChange}
                      onInputChange={setLocationFilter}
                      isMulti={false}
                      cacheOptions
                      defaultOptions
                    />
                  </FormControl>
                )}
              </Field>
            </HStack>
            <Heading as='h3' size='md' marginTop='5pt' marginBottom='5pt'>Cargo</Heading>
            {
              shipDesc !== undefined && location !== 0 ?
                <CargoAdder locationId={location!} cargoUpdater={setCargo} capacity={shipDesc.CargoSpace!} disableSubmit={toggleSubmit} /> : ''
            }
            <Button
              colorScheme="green"
              type="submit"
              isLoading={props.isSubmitting}
              isDisabled={isEnabled}
            >Create</Button>
          </Form>
        )}
      </Formik>
    </Box>
  )
}


