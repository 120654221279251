import React, { useState, SyntheticEvent } from "react";
import { CargoWrapper } from "../../types/CargoWrapper";
import AsyncSelect from 'react-select/async';
import CommodityProvider from "../../utils/providers/commodityProvider";
import { customStyles } from "../../utils/selectStyles";
import { Button, VStack, HStack, Input, FormLabel } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes } from "@fortawesome/free-solid-svg-icons";

type OnloadProps = {
  key: number;
  cargo: CargoWrapper;
  updater: (c: CargoWrapper) => void;
  remover: (key: number) => void;
  locationId: number;
}

export function AddOnload(props: OnloadProps) {
  const [commodityFilter, setCommodityFilter] = useState('');

  const updateOnload = (data: any) => {
    let cargo = props.cargo;
    if (data.label !== undefined) {
      cargo.commodityId = data.value;
      cargo.commodityLabel = data.label;
    } else {
      if (data.currentTarget.id === 'cargo-amount') {
        cargo.amount = parseInt(data.currentTarget.value);
      }
      if (data.currentTarget.id === 'cargo-value') {
        cargo.value = parseInt(data.currentTarget.value);
      }
      if (data.currentTarget.id === 'cargo-unit-price'){
        cargo.unitPrice = parseInt(data.currentTarget.value);
      }
    }
    props.updater(cargo);
  }

  const removeOnload = (e: SyntheticEvent) => {
    e.preventDefault();
    props.remover(props.cargo.key);
  }

  const loadCommodityOptions =
    async () => await CommodityProvider.GetLocationCommodityDropdownData(props.locationId, true, commodityFilter);

  return (
    <HStack key={props.cargo.key} borderRadius='3pt' borderWidth='1pt' padding='5pt' spacing='2pt' id={'onload'+props.cargo.key}>
      <FontAwesomeIcon icon={faBoxes} style={{ paddingLeft: '5', paddingRight: '5pt', fontSize: '30pt' }}/>
      <VStack>
        <FormLabel marginBottom='0pt' htmlFor='commodity-select' className='bold'>Commodity</FormLabel>
        <AsyncSelect
          id='commodity-select'
          styles={customStyles}
          loadOptions={loadCommodityOptions}
          onChange={updateOnload}
          onInputChange={setCommodityFilter}
          isMulti={false}
          cacheOptions
          defaultOptions={true}
        />
      </VStack>
      <VStack>
        <FormLabel marginBottom='0pt' htmlFor='cargo-amount' className='bold'>Amount</FormLabel>
        <Input id="cargo-amount" type="number" onChange={updateOnload} />
      </VStack>
      <VStack>
        <FormLabel marginBottom='0pt' htmlFor='cargo-value' className='bold'>Price</FormLabel>
        <Input width='max' id="cargo-value" type="number" onChange={updateOnload} />
      </VStack>
      <VStack>
        <FormLabel marginBottom='0pt' htmlFor='cargo-unit-price' className='bold'>Unit price</FormLabel>
        <Input id="cargo-unit-price" type="decimal" onChange={updateOnload} />
      </VStack>
      <Button colorScheme='red' onClick={removeOnload}>Remove cargo</Button>
    </HStack>
  )
}
