import ApiClient from '../client/ApiClient'
import { SelectOption } from '../types';
import { ShipDescriptorVM } from '../../viewModels/ShipDescriptorVM';

class ShipProvider {

  async GetCargoShipsDropdownData(filter:string = ''): Promise<SelectOption[]> {
    if (filter.length < 3){
      return [
        {
          label: "Type the at least 3 letters" ,
          value: "ph"
        }
      ]
    }
    
    let options = await ApiClient.GetCargoShips(filter);
    // TODO user fleets
    
    return options.map((s) => {
      return {
        value: s.Id.toString(),
        label: s.Name
      }
    })
  }

  async GetShipDescriptor(id:number): Promise<ShipDescriptorVM>{
    let result = ApiClient.GetShip(id);
    return result;
  }
}

export default new ShipProvider()