import React, { useState } from "react";
import { ActiveTrades } from "../trades/ActiveTrades";
import { CompletedTrades } from "../trades/CompletedTrades";
import { Box, Heading } from "@chakra-ui/react";

type HomeProps = {
  
}

export function Home(props: HomeProps) {
  const [needRefresh, setNeedRefresh] = useState(false);

  const refresher = () => {
    setNeedRefresh(!needRefresh);
  }

  return (
    <Box className='trades-wrapper' id='trades-active'>
      <Heading as='h2' size='lg' marginBottom='5pt' marginTop='5pt'>Active Trades</Heading>
      <ActiveTrades requestRefresh={refresher}/>
      <Heading as='h2' size='lg' marginBottom='5pt' marginTop='5pt'>Completed Trades</Heading>
      <CompletedTrades refresh={needRefresh}/>
    </Box>
  )
}

