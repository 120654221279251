import React, { useState, useEffect, SyntheticEvent } from "react";
import { TradeRunVM } from "../../viewModels/TradeRunVM";
import { OnloadVM } from "../../viewModels/OnloadVM";
import { OffloadVM } from "../../viewModels/OffloadVM";
import { OffloadAdder } from "./OffloadAdder";
import { OffloadDisplay } from "./OffloadDisplay";
import { UpDownChevronIcon } from "../misc/UpDownChevronIcon";
import { TradeCloseForm } from "./TradeCloseForm";
import { HStack, VStack, Button, Flex, Heading, useToast } from "@chakra-ui/react"
import { SimpleGrid } from "@chakra-ui/react"
import { Box } from "@chakra-ui/react"
import { TradeDeleteAlert } from './TradeDeleteAlert';
import ApiClient from "../../utils/client/ApiClient";

type TradeDisplayProps = {
  trade: TradeRunVM;
  outDater: () => void;
}

export class ManifestItem {
  CommodityName: string;
  CommodityId: number;
  Amount: number;
  Id: number;
}

export function TradeDisplay(props: TradeDisplayProps) {
  const [manifest, setManifest] = useState<ManifestItem[]>([]);
  const [closeForm, setCloseForm] = useState(false);
  const [outOfDate, setOutOfDate] = useState(false);
  const [deleteAlert, setDeleteAlert] = useState(false);
  const toast = useToast();

  const generateManifest = (): ManifestItem[] => {
    // Group items
    let onloadByCommodity = props.trade.Onloads.reduce((r: any, a: any) => {
      r[a.CommodityId] = [...r[a.CommodityId] || [], a];
      return r;
    }, {});

    let offloadByCommodity = props.trade.Offloads.reduce((r: any, a: any) => {
      r[a.CommodityId] = [...r[a.CommodityId] || [], a];
      return r;
    }, {});

    let manifest: ManifestItem[] = [];
    for (let oGroupKey of Object.keys(onloadByCommodity)) {
      let manifestItem: ManifestItem = new ManifestItem();
      let group: OnloadVM[] = onloadByCommodity[oGroupKey];

      manifestItem.Amount = group.map((o => o.Amount)).reduce((a, b) => a + b);
      manifestItem.CommodityName = group[0].CommodityName
      manifestItem.Id = manifest.length;
      manifestItem.CommodityId = group[0].CommodityId

      //Verify that commodity wasn't offloaded
      if (offloadByCommodity[oGroupKey] !== undefined) {
        manifestItem.Amount -= offloadByCommodity[oGroupKey]
          .map((o: OffloadVM) => o.Amount)
          .reduce((a: number, b: number) => a + b);
      }
      manifest.push(manifestItem);
    }
    return manifest;
  }

  useEffect(() => {
    setManifest(generateManifest());
  }, [outOfDate])

  const closeTrade = (e: SyntheticEvent) => {
    e.preventDefault();
    setCloseForm(!closeForm);
  };

  const forceUpdate = () => {
    setCloseForm(false);
    setOutOfDate(true);
    props.outDater();
  }

  const onClose = () => setDeleteAlert(false)
  const cancelRef = React.useRef()

  const onDeleteConfirm = async () => {
    let result = await ApiClient.DeleteTrade(props.trade.Id);

    toast({
      title: result.message,
      status: result.success ? 'success' : 'error',
      duration: 9000,
      isClosable: true,
    })
    
    props.outDater();
    setDeleteAlert(false)
  }

  return (
    <Box width='full' className='trade-display'>
      <Flex className='trade-information'>
        <Box className='trade-display-section'>
          <Heading as='h3' size='md' marginBottom='5pt' marginTop='5pt'>Trade Information</Heading>
          <VStack spacing='0' align='left'>
            <HStack>
              <label className='bold'>Trade ID:</label>
              <label>{'#' + props.trade.OwnerId.slice(0, 6).toUpperCase() + '-' + props.trade.Id}</label>
            </HStack>
            <HStack>
              <label className='bold'>Source:</label>
              <label>{props.trade.SourceName}</label>
            </HStack>
            <HStack>
              <label className='bold'>Ship:</label>
              <label>{props.trade.ShipName}</label>
            </HStack>
            <HStack>
              <label className='bold'>Bottomline:</label>
              <label className={props.trade.Bottomline >= 0 ? 'text-green' : 'text-red'}>
                {props.trade.Bottomline + ' aUEC'}
              </label>
            </HStack>
          </VStack>
        </Box>
        <Box className='trade-display-section'>
          <Heading as='h3' size='md' marginTop='5pt' marginBottom='5pt'>Cargo manifest</Heading>
          <SimpleGrid columns={2}>
            {manifest.map(m => {
              return (
                <Box className='cargo-manifest-item' key={m.Id}>
                  <VStack align='left' spacing='0'>
                    <HStack>
                      <label className='bold'>Commodity:</label>
                      <label>{m.CommodityName}</label>
                    </HStack>
                    <HStack>
                      <label className='bold'>Amount:</label>
                      <label>{m.Amount + ' Units'}</label>
                    </HStack>
                  </VStack>
                </Box>
              )
            })}
          </SimpleGrid>
        </Box>
        <Box className='trade-display-section'>
          <Heading as='h3' size='md' marginTop='5pt' marginBottom='5pt'>Offloads</Heading>
          {manifest.some(m => m.Amount !== 0) ? <OffloadAdder
            offloads={props.trade.Offloads}
            tradeId={props.trade.Id}
            manifest={manifest}
            outDater={forceUpdate}
          /> : ''}
          <div className='offloads-list'>
            {props.trade.Offloads.map(o => <OffloadDisplay offload={o} key={o.Id} />)}
          </div>
        </Box>
      </Flex>
      <Box className='trade-display-actions' marginLeft='5pt'>
        <HStack spacing='3pt'>
          <Button colorScheme='blue' onClick={closeTrade}>Close trade <UpDownChevronIcon isDown={!closeForm} /></Button>
          <Button colorScheme='red' onClick={() => setDeleteAlert(true)}>Delete trade</Button>
        </HStack>
        {closeForm ? <TradeCloseForm TradeId={props.trade.Id} forceUpdate={forceUpdate} /> : ''}
        <TradeDeleteAlert isOpen={deleteAlert} onClose={onClose} cancelRef={cancelRef} onDeleteConfirm={onDeleteConfirm}/>
      </Box>
    </Box>
  )
}


