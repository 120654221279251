import React, { useState, useEffect } from "react";
import { TradeRunVM } from "../../viewModels/TradeRunVM";
import ApiClient from "../../utils/client/ApiClient";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td, Button, Box
} from "@chakra-ui/react"
import { CompletedTradeDetail } from "./CompletedTradeDetail";

type CompletedTradesProps = {
  refresh: boolean;
}

export function CompletedTrades(props: CompletedTradesProps) {
  const [trades, setTrades] = useState<TradeRunVM[]>([]);
  const [detail, setDetail] = useState<TradeRunVM>();

  useEffect(() => {
    ApiClient.GetTrades("&$filter=State eq 'Closed' or State eq 'Lost'")
      .then(data => setTrades(data)
      );
  }, [props.refresh]);

  return (
    <Box id='completed-trades'>
      {detail !== undefined ? <CompletedTradeDetail trade={detail} /> : ''}
      <Box className='completed-trades-list'>
        <Table variant="simple" bg='white' color='black' borderColor='black' size='sm'>
          <Thead bg='#0C1B31' color='white'>
            <Tr>
              <Th borderColor='black' color='white'>ID</Th>
              <Th borderColor='black' color='white'>Created at</Th>
              <Th borderColor='black' color='white'>Closed at</Th>
              <Th borderColor='black' color='white'>Bottomline</Th>
              {/* <Th borderColor='black'>Purchase unit prices</Th>
              <Th borderColor='black'>Sale unit prices</Th> */}
              <Th borderColor='black' color='white'>State</Th>
              <Th borderColor='black' color='white'>Ship</Th>
              <Th borderColor='black' color='white'>Starting location</Th>
              <Th borderColor='black' color='white'>Destinations</Th>
              <Th borderColor='black' color='white'>Note</Th>
              <Th borderColor='black' color='white'>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {trades.map((t: TradeRunVM) => (
              <Tr key={t.Id}>
                <Td>{t.OwnerId.slice(0, 6).toUpperCase() + '-' + t.Id}</Td>
                <Td>{new Date(t.CreatedAt).toLocaleString()}</Td>
                <Td>{new Date(t.ClosedAt).toLocaleString()}</Td>
                <Td isNumeric fontWeight='bold' color={t.Bottomline < 0 ? 'red' : 'green'}>{t.Bottomline}</Td>
                {/* <Td>{t.Onloads.map(o => o.CommodityName + ': ' + o.UnitPrice + ' aUEC; ')}</Td>
                <Td>{t.Offloads.map(o => o.CommodityName + ': ' + o.UnitPrice + ' aUEC; ')}</Td> */}
                <Td color='white' fontWeight='bold' bg={t.State === 'Closed' ? 'green' : 'red'}>{t.State}</Td>
                <Td>{t.ShipName}</Td>
                <Td>{t.SourceName}</Td>
                <Td>{t.Offloads.map(r => r.DropOffName).join(', ')}</Td>
                <Td>{t.Note}</Td>
                <Td>
                  {
                    detail?.Id !== t.Id ? <Button colorScheme='blue' onClick={() => setDetail(t)}>Detail</Button>
                    : <Button colorScheme='orange' onClick={() => setDetail(undefined)}>Close</Button>
                  }
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Box>
  );
}
