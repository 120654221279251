import { OffloadVM } from "./OffloadVM";
import { OnloadVM } from "./OnloadVM";

export class TradeRunVM {
  Id: number;
  OwnerId: string;
  CreatedAt: string;
  ClosedAt: string;
  State: string;
  ShipId: number;
  ShipName: string;
  MaxCapacity: number;
  Bottomline: number;
  Note: string;
  SourceName: string;
  SourceId: string;
  Onloads: OnloadVM[];
  Offloads: OffloadVM[];
}
