import React, { SyntheticEvent, useState } from "react";
import { OffloadVM } from "../../viewModels/OffloadVM";
import { ManifestItem } from "./TradeDisplay";
import AsyncSelect from 'react-select/async';
import { customStyles } from "../../utils/selectStyles";
import { SelectOption } from "../../utils/types";
import LocationsProvider from "../../utils/providers/locationsProvider";
import { ErrorLabel } from "../ErrorLabel";
import ApiClient from "../../utils/client/ApiClient";
import { Box, Button, FormControl, FormLabel, Input, useToast } from "@chakra-ui/react";
import { Formik, Field, Form, FieldConfig } from "formik";
import { Select } from "@chakra-ui/react"
import { UpDownChevronIcon } from "../misc/UpDownChevronIcon";


type OffloadAdderProps = {
  offloads: OffloadVM[];
  tradeId: number;
  manifest: ManifestItem[];
  outDater: () => void;
};

export function OffloadAdder(props: OffloadAdderProps) {
  const [toggleForm, setToggleForm] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const toast = useToast()

  const addOffload = (e: SyntheticEvent) => {
    e.preventDefault();
    setToggleForm(!toggleForm);
  };

  const optionsProvider = (): SelectOption[] => {
    return props.manifest.filter(c => c.Amount > 0).map((c) => {
      return {
        value: c.CommodityId.toString(),
        label: c.CommodityName
      }
    })
  }

  const addSubmitHandler = async (offload: OffloadVM) => {
    let manCommodity = props.manifest.find(c => c.CommodityId.toString() === offload.CommodityId);
    if (manCommodity!.Amount < offload.Amount) {
      setErrorMsg("You can't sell more than is on the manifest");
    } else {
      let result = await ApiClient.AddOffload(props.tradeId, offload);
      toast({
        title: result.message,
        status: result.success ? 'success' : 'error',
        duration: 9000,
        isClosable: true,
      })

      setToggleForm(false);
      props.outDater();
    }
  }

  return (
    <Box className='offload-adder'>
      <Button colorScheme="blue" onClick={addOffload}>Add offload<UpDownChevronIcon isDown={!toggleForm}/></Button>
      {toggleForm ? <AddOffloadForm
        optionsProvider={optionsProvider}
        errorMsg={errorMsg}
        addSubmitHandler={addSubmitHandler}
      /> : ''}
    </Box>
  );
}

type AddOffloadFormProps = {
  optionsProvider: () => SelectOption[]
  addSubmitHandler: (offload: OffloadVM) => void;
  errorMsg: string;
}

function AddOffloadForm(props: AddOffloadFormProps) {
  const [locationFilter, setLocationFilter] = useState('');
  const [location, setLocation] = useState<string>('');
  const [error, setError] = useState('');

  const handleLocationChange = (selected: any) => {
    setLocation(selected.value);
  }

  const loadCommodityOptions = () => props.optionsProvider();

  const loadLocationOptions = async () => await LocationsProvider.GetLocationsDropdownData(locationFilter)

  return (
    <Box className='add-offload-form'>
      <ErrorLabel message={error} />
      <Formik
        initialValues={
          {
            location: '',
            commodity: '',
            amount: 0,
            income: 0,
            unitPrice: 0
          }
        }
        onSubmit={
          async (values, actions) => {
            let offload = new OffloadVM();
            offload.CommodityId = values.commodity!;
            offload.DropOffId = location;
            offload.Amount = values.amount!;
            offload.Price = values.income!;
            offload.UnitPrice = values.unitPrice!;

            if (offload.CommodityId === '' || offload.DropOffId === '' || offload.UnitPrice.toString() === '0' 
            || offload.Amount.toString() === '0' || offload.Price.toString() === '0') {
              setError("All fields are required or can't be zero");  
            } else {
              setError('')
              actions.setSubmitting(false)
              props.addSubmitHandler(offload);
            }
          }
        }
      >
        {(props) => (
          <Form>
            <Field name='location'>
              {(field: FieldConfig, form: any) => (
                <FormControl isRequired>
                  <FormLabel htmlFor='location'>Location</FormLabel>
                  <AsyncSelect
                    styles={customStyles}
                    loadOptions={loadLocationOptions}
                    onChange={handleLocationChange}
                    onInputChange={setLocationFilter}
                    isMulti={false}
                    cacheOptions
                    defaultOptions
                  />
                </FormControl>
              )}
            </Field>
            <Field name='commodity'>
              {(field: FieldConfig, form: any) => (
                <FormControl isRequired>
                  <FormLabel htmlFor='commodity'>Commodity</FormLabel>
                  <Select id='commodity' placeholder='Select commodity' onChange={props.handleChange}>
                    {loadCommodityOptions().map(o => <option style={{color: 'black'}} value={o.value}>{o.label}</option>)}
                  </Select>
                </FormControl>
              )}
            </Field>
            <Field name='amount'>
              {(field: any, form: any) => (
                <FormControl isRequired>
                  <FormLabel htmlFor="amount">Amount</FormLabel>
                  <Input id="amount" placeholder="0" onChange={props.handleChange} />
                </FormControl>
              )}
            </Field>
            <Field name='income'>
              {(field: any, form: any) => (
                <FormControl isRequired>
                  <FormLabel htmlFor="income">Income</FormLabel>
                  <Input id="income" placeholder="0" onChange={props.handleChange} />
                </FormControl>
              )}
            </Field>
            <Field name='unitPrice'>
              {(field: any, form: any) => (
                <FormControl isRequired>
                  <FormLabel htmlFor="unitPrice">Unit price</FormLabel>
                  <Input id="unitPrice" placeholder="0" onChange={props.handleChange} />
                </FormControl>
              )}
            </Field>
            <Button
              colorScheme="blue"
              type="submit"
              isLoading={props.isSubmitting}
            >
              Add offload
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
