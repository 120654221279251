import ApiClient from '../client/ApiClient'
import { SelectOption } from '../types';

class CommodityProvider {
  GetCommodityDropdownData(): SelectOption[] {
    // TODO actual data
    // TODO user fleets
    return [
      { value: '1', label: 'Taranite' },
      { value: '2', label: 'Beryl' },
      { value: '3', label: 'Laranite' },
    ]
  }

  async GetLocationCommodityDropdownData(locationId: number, sold: boolean, filter: string = ''): Promise<SelectOption[]> {
     let options = sold ? await ApiClient.GetSoldCommoditiesForLocation(locationId, filter)
      : await ApiClient.GetSoldCommoditiesForLocation(locationId, filter);

    return options.map(c => {
      return {
        label: c.LocalizedName,
        value: c.Id.toString()
      }
    }).filter(c => c.label.toLowerCase().includes(filter.toLowerCase()))
  }
}

export default new CommodityProvider();