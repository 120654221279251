import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Home } from './routes/Home';
import { Settings } from "./routes/Settings";
import { List, ListItem, Box, Divider } from "@chakra-ui/react";

type MainContentProps = {
  
}

export function MainContent(props: MainContentProps) {
  return (
    <Router>
      <Box>
        <nav className='menu-main'>
          <List>
            <ListItem padding='5pt'>
              <Link to="/">Trades</Link>
            </ListItem>
            <Divider orientation='vertical' height='10pt'/>
            <ListItem padding='5pt'>
              <Link to="/fleet">Fleet</Link>
            </ListItem>
            <Divider orientation='vertical' height='10pt'/>
            <ListItem padding='5pt'>
              <Link to="/analytics">Analytics</Link>
            </ListItem>
            <Divider orientation='vertical' height='10pt'/>
            <ListItem padding='5pt'>
              <Link to="/settings">Settings</Link>
            </ListItem>
          </List>
        </nav>
      </Box>
      <Box>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>
        </Switch>
      </Box>
    </Router>
  )
}