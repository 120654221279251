import ApiClient from '../client/ApiClient'
import { SelectOption } from '../types';
import { RetailLocationVM } from '../../viewModels/RetailLocationVM';

class LocationsProvider {
  _retailLocations: RetailLocationVM[];

  /**
   *
   */
  constructor() {
    this._retailLocations = [];
  }

  async GetLocations(): Promise<RetailLocationVM[]> {
    console.log(this._retailLocations)
    if (this._retailLocations.length === 0) {
      this._retailLocations = await ApiClient.GetRetailLocations();
    }
    return this._retailLocations;
  }

  /**
   * Returns retail locations as {label:'', value:''} array 
   */
  async GetLocationsDropdownData(filter:string = ''): Promise<SelectOption[]> {
    if (filter.length < 3){
      return [
        {
          label: "Type the at least 3 letters" ,
          value: "ph"
        }
      ]
    }
    let options = await ApiClient.GetRetailLocations(filter)
    return options.map((l) => {
      return {
        label: l.StoreLocationChain + ' - ' + l.StoreName ,
        value: l.Id.toString()
      }
    });
  }
}

export default new LocationsProvider();