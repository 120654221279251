import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme, Theme, Colors } from "@chakra-ui/react"

const colors: Colors = {
  brand: {
    900: "#1a365d",
    800: "#153e75",
    700: "#2a69ac",
  },

  //white: "#282c34"
}

const typography = {
  fonts: {
    heading: 'Electrolize'
  }
}

const components = {
  Button: {
    baseStyle: {
      color: 'whitesmoke',
      fontFamily: 'Electrolize'
    },
    
  },
  FormControl: {
    baseStyle: {
      fontFamily: 'Electrolize'
    }
  },
  Heading :{
    baseStyle: {
      fontFamily: 'Electrolize'
    },
  }
}

const theme: Theme = extendTheme({ colors, typography, components })

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
