import React, { useState, useEffect } from "react";
import { NewTrade } from "./NewTrade";
import ApiClient from "../../utils/client/ApiClient";
import { TradeDisplay } from './TradeDisplay';
import { TradeRunVM } from "../../viewModels/TradeRunVM";
import { UpDownChevronIcon } from '../misc/UpDownChevronIcon'
import { Box, VStack, Button } from "@chakra-ui/react";

type ActiveTradesProps = {
  requestRefresh: () => void;
}

export function ActiveTrades(props: ActiveTradesProps) {
  const [openNew, setOpenNew] = useState(false)
  const [trades, setTrades] = useState<TradeRunVM[]>([]);
  const [needUpdate, setNeedUpdate] = useState(false);

  useEffect(() => {
    ApiClient.GetTrades("&$filter=State eq 'Open'")
      .then(data =>
        setTrades(data)
      );
  }, [needUpdate])

  const toggleNew = () => {
    setOpenNew(!openNew);
  }

  const forceUpdate = () => {
    setOpenNew(false);
    props.requestRefresh();
    setNeedUpdate(!needUpdate);
  }

  return (
    <Box>
      <Button colorScheme='blue' onClick={toggleNew}>
        New Trade
        <UpDownChevronIcon isDown={!openNew}/>
      </Button>
      {openNew ? <NewTrade updateTrades={forceUpdate} /> : ''}
      <VStack width='auto' className='active-trades-list'>
        {trades.map((t: TradeRunVM) => <TradeDisplay trade={t} key={t.Id} outDater={forceUpdate} />)}
      </VStack>
    </Box>
  )
}
