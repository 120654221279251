import React, { useState, useEffect } from 'react';
import './App.css';
import { Login } from './components/auth/Login';
import { MainContent } from './components/MainContent';
import { Header } from './Header';

function App() {
  const [token, setToken] = useState('');
  const [username, setUsername] = useState('');

  //Check local storage for a token
  useEffect(() => {
    setToken(localStorage.getItem('token') || '');
    setUsername(localStorage.getItem('username') || '');
  }, [token, username])

  const loginHandler = (token: string) => {
    setToken(token);

    //Save the token in local storage
    localStorage.setItem('token', token);   
  }

  const usernameSetter = (username:string) => {
    localStorage.setItem('username', username)
    setUsername(username)
  }

  return (
    <div className="App">
      <header className='App-header'>
        <Header token={token} username={username} />
      </header>
      <div className='content'>
        {token === '' ? <Login tokenSetter={loginHandler} nameSetter={usernameSetter} /> : <MainContent />}
      </div>
    </div>
  );
}

export default App;

