import React from 'react';
import { SyntheticEvent } from 'react';
import { useState } from 'react';
import { ErrorLabel } from '../ErrorLabel';
import ApiClient from '../../utils/client/ApiClient'
import { UpDownChevronIcon } from '../misc/UpDownChevronIcon'
import { SignUp } from './SignUp';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Box,
  VStack
} from "@chakra-ui/react"
import { Formik, Field, Form } from 'formik';

type LoginProps = {
  tokenSetter: (token: string) => void;
  nameSetter: any
}

export function Login(props: LoginProps) {
  const [error, setError] = useState('');
  const [toggleSignUp, setToggleSignUp] = useState(false);

  const handleToggleSignUp = (e: SyntheticEvent) => {
    setToggleSignUp(!toggleSignUp)
  }

  return (
    <Box color='white' margin='auto' width='max-content'>
      <VStack width='300pt'>
        {error === '' ? '' : <ErrorLabel message={error} />}
        <Formik
          initialValues={{
            username: '',
            password: '',
          }}
          onSubmit={async (values, actions) => {
            let result = await ApiClient.Authorize(values.username, values.password);

            if (result.error !== '') {
              setError(result.error);
            } else if (result.token !== '') {
              setError('')
              props.tokenSetter(result.token)
              props.nameSetter(values.username)
            }
            actions.setSubmitting(false)
          }}
        >
          {(props) => (
            <Form>
              <Field name="username">
                {(field: any, form: any) => (
                  <FormControl isRequired>
                    <FormLabel htmlFor="username">Username</FormLabel>
                    <Input id="username" placeholder="Username" onChange={props.handleChange} />
                  </FormControl>
                )}
              </Field>
              <Field name="password">
                {(field: any, form: any) => (
                  <FormControl isRequired>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <Input id="password" type='password' placeholder="Pasword" onChange={props.handleChange} />
                  </FormControl>
                )}
              </Field>
              <FormErrorMessage>{error}</FormErrorMessage>
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={props.isSubmitting}
                marginTop='5pt'
                minWidth='300pt'
              >
                Sign in
              </Button>
            </Form>
          )}
        </Formik>
        <VStack id='sign-up'>
          <Button colorScheme='green' minWidth='300pt' onClick={handleToggleSignUp}>
            Sign up
            <UpDownChevronIcon isDown={!toggleSignUp} />
          </Button>
          {toggleSignUp ? <SignUp /> : ''}
        </VStack>
      </VStack>
    </Box>
  )
}

