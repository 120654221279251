import { ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons'
import React from 'react'

type UpDownChevronIconProps = {
  isDown:boolean
}

export function UpDownChevronIcon (props:UpDownChevronIconProps) {
  return (
    props.isDown ? <ChevronDownIcon style={{ paddingLeft: '5'}}/> : <ChevronUpIcon style={{ paddingLeft: '5'}}/>
  )
}