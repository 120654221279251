import React from "react";
import {Button, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from "@chakra-ui/react"
import { oxfordBlue } from "../../colors";

type TradeDeleteAlertProps = {
  isOpen:any;
  cancelRef:any;
  onClose:any;
  onDeleteConfirm:any;
}

export function TradeDeleteAlert(props:TradeDeleteAlertProps){
  return (
    <AlertDialog
        isOpen={props.isOpen}
        leastDestructiveRef={props.cancelRef}
        onClose={props.onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent backgroundColor={oxfordBlue} color='white'>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Trade
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button colorScheme='blue' ref={props.cancelRef} onClick={props.onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={props.onDeleteConfirm} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
  )
}