import { Box } from "@chakra-ui/react"
import React from "react"

type ErrorLabelProps = {
  message:string;
}

export function ErrorLabel(props:ErrorLabelProps){
  return (
    <Box minWidth='300pt' textAlign='center' fontFamily='lato' backgroundColor='red.600' borderRadius='3pt'>
      <p>{props.message}</p>
    </Box>
  )
}