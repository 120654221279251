import React, {  } from "react";
//import Select from 'react-select';
import ApiClient from "../../utils/client/ApiClient";
import { Button, Select, FormControl, FormLabel, Input, useToast } from "@chakra-ui/react"
import { Formik, Field, Form } from "formik";

type TradeCloseFormProps = {
  TradeId: number;
  forceUpdate: () => void;
}

export function TradeCloseForm(props: TradeCloseFormProps) {
  const toast = useToast();

  return (
    <Formik
      initialValues={{
        status: '',
        note: ''
      }}
      onSubmit={async (values) => {
        let result = await ApiClient.CloseTrade(props.TradeId, values.status, values.note);
        toast({
            title: result.message,
            status: result.success ? 'success' : 'error',
            duration: 9000,
            isClosable: true,
          })
        
        props.forceUpdate();
      }}
    >
      {(props) => (
        <Form id='trade-close-form'>
          <Field name='status'>
            {() => (
              <FormControl isRequired>
                <FormLabel htmlFor='status'>Status</FormLabel>
                <Select id='status' placeholder='Select status' onChange={props.handleChange}>
                  <option style={{color: 'black'}} value='Closed'>Closed</option>
                  <option style={{color: 'black'}} value='Lost'>Lost</option>
                </Select>
              </FormControl>
            )}
          </Field>
          <Field name='note'>
            {() => (
              <FormControl>
                <FormLabel htmlFor="note">Note</FormLabel>
                <Input id="note" onChange={props.handleChange} />
              </FormControl>
            )}
          </Field>
          <Button
            colorScheme="orange"
            type="submit"
            isLoading={props.isSubmitting}
          >
            Close trade
            </Button>
        </Form>
      )}
    </Formik>
  );
}
