import React from 'react'
import { Box, Link } from '@chakra-ui/react';

export function Logout() {
  const logoutHandler = () => {
    localStorage.removeItem('token');
  }

  return (
    <Box id='logout'>
      <Link href="/" onClick={logoutHandler}>Log out</Link>
    </Box>
  )
}