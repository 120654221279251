import axios, { AxiosInstance } from 'axios';
import { AuthenticationEndpoint, TraderunsEndpoint, RetailProductsEndpoint, RetailLocationsEndpoint, ShipDescriptorsEndpoint } from '../../utils/endpoints';
import { TradeRunVM } from '../../viewModels/TradeRunVM';
import { RetailProductVM } from '../../viewModels/RetailProductVM';
import { RetailLocationVM } from '../../viewModels/RetailLocationVM';
import { ShipDescriptorVM } from '../../viewModels/ShipDescriptorVM';
import { OffloadVM } from '../../viewModels/OffloadVM';
import { RegisterVM } from '../../viewModels/RegisterVM';

/// NOTES
// filtering by contains two options 
// ?$filter=contains(tolower(StoreName),'shu') or contains(tolower(StoreLocationChain),'shu')

//await this.instance.get(this.url + '/weatherforecast')
//await this.instance.get(this.url + '/shipdescriptors')
//await this.instance.post(this.url + '/shipdescriptors', { })

type ResultObject<T> = {
  success: boolean,
  message: string,
  data?: T
}

class ApiClient {
  token: string;
  instance: AxiosInstance;
  url: string;

  constructor() {
    this.url = process.env.REACT_APP_API_URL || '';
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    })

    let token = localStorage.getItem('token')
    if (token !== null) {
      this.SetToken(token)
    }
  }

  SetToken(token: string) {
    this.token = token;

    // Save token
    localStorage.setItem('token', token);

    this.instance.interceptors.request.use(function (config) {
      config.headers['Authorization'] = token ? `Bearer ${token}` : '';
      config.headers['Content-Type'] = 'application/json';
      return config;
    });
  }

  //#region Trades

  /**
   * 
   * @param filter filter in format '&$filter=[filter]
   */
  async GetTrades(filter: string = ''): Promise<TradeRunVM[]> {
    let result = await this.instance.get(
      this.url + TraderunsEndpoint + "?$expand=Onloads,Offloads" + filter
    );
    return result.data.value
  }

  async CreateTrade(model: TradeRunVM): Promise<ResultObject<TradeRunVM>> {
    let result = await this.instance.post(
      this.url + TraderunsEndpoint, model
    )

    if (result.status !== 200) {
      return {
        success: false,
        message: `Couldn't create trade: ${result.statusText}`,
        data: undefined
      };
    }

    return {
      success: true,
      message: `Trade created with ID: ${result.data.value}`,
      data: result.data.value
    };
  }

  async AddOffload(tradeId: number, model: OffloadVM): Promise<ResultObject<number>> {
    let result = await this.instance.post(
      this.url + TraderunsEndpoint + `(${tradeId})/AddOffload`, { Offload: model }
    )

    if (result.status !== 200) {
      return {
        success: false,
        message: `Couldn't create offload: ${result.statusText}`,
        data: undefined
      };
    }

    return {
      success: true,
      message: `Offload created with ID: ${result.data.value}`,
      data: 0
    };
  }

  async CloseTrade(tradeId: number, state: string, note: string): Promise<ResultObject<number>> {
    let result = await this.instance.post(
      this.url + TraderunsEndpoint + `(${tradeId})/CloseTrade`, { State: state, Note: note }
    )
    if (result.status !== 200) {
      return {
        success: false,
        message: `Couldn't close trade: ${result.statusText}`,
        data: undefined
      };
    }

    return {
      success: true,
      message: `Trade closed`,
      data: 0
    };
  }

  async DeleteTrade(tradeId: number): Promise<ResultObject<number>> {
    let result = await this.instance.delete(this.url + TraderunsEndpoint + `(${tradeId})`)
    
    if (result.status !== 200) {
      return {
        success: false,
        message: `Couldn't delete trade: ${result.statusText}`,
        data: undefined
      };
    }

    return {
      success: true,
      message: `Trade deleted`,
      data: 0
    };
  }

  //#endregion

  //#region Commodities
  async GetCommodities(): Promise<RetailProductVM[]> {
    let result = await this.instance.get(
      this.url + RetailProductsEndpoint + "?$filter=RetailType eq 'Commodity'"
    )
    return result.data;
  }

  async GetSoldCommoditiesForLocation(locationId: number, filter: string = ''): Promise<RetailProductVM[]> {
    let result: any;
    result = await this.instance.get(
      this.url + RetailLocationsEndpoint + `(${locationId})?$expand=solditems`
    );
    return result.data.SoldItems
  }

  async GetBoughtCommoditiesForLocation(locationId: number, filter: string = ''): Promise<RetailProductVM[]> {
    let result: any;
    result = await this.instance.get(
      this.url + RetailLocationsEndpoint + `(${locationId})?$expand=boughtitems`
    );
    return result.data.BoughtItems
  }

  //#endregion

  //#region RetailLocations
  async GetRetailLocations(filter: string = ''): Promise<RetailLocationVM[]> {
    let result: any;
    if (filter === '') {
      result = await this.instance.get(
        this.url + RetailLocationsEndpoint
      )
    } else {
      result = await this.instance.get(
        this.url + RetailLocationsEndpoint +
        `?$filter=contains(tolower(StoreName),'${filter.toLocaleLowerCase()}') 
        or contains(tolower(StoreLocationChain),'${filter.toLocaleLowerCase()}')`
      )
    }

    return result.data.value;
  }

  //#endregion

  //#region Ships
  async GetShips(filter: string): Promise<ShipDescriptorVM[]> {
    let result: any;
    if (filter === '') {
      result = await this.instance.get(
        this.url + ShipDescriptorsEndpoint
      )
    } else {
      result = await this.instance.get(
        this.url + ShipDescriptorsEndpoint +
        `?$filter=contains(tolower(Name),'${filter.toLocaleLowerCase()}')`
      )
    }

    return result.data.value;
  }

  async GetCargoShips(filter: string): Promise<ShipDescriptorVM[]> {
    let result: any;
    if (filter === '') {
      result = await this.instance.get(
        this.url + ShipDescriptorsEndpoint + '?$filter=CargoSpace gt 0'
      )
    } else {
      result = await this.instance.get(
        this.url + ShipDescriptorsEndpoint +
        `?$filter=contains(tolower(Name),'${filter.toLocaleLowerCase()}') and CargoSpace gt 0`
      )
    }

    return result.data.value;
  }

  async GetShip(id: number): Promise<ShipDescriptorVM> {
    let result = await this.instance.get(this.url + ShipDescriptorsEndpoint + `(${id})`);
    return result.data;
  }

  //#endregion

  async Authorize(username: string, password: string) {
    try {
      let result = await this.instance.post(this.url + AuthenticationEndpoint + '/login', {
        username: username,
        password: password
      })
      this.SetToken(result.data.token)
      return { token: result.data.token, error: '' };
    } catch (error) {
      if (error.response.status === 401) {
        return { token: '', error: 'Wrong username or password' }
      } else {
        return { token: '', error: 'Unspecified error' }
      }
    }
  }

  async SignUp(model: RegisterVM) {
    try {
      await this.instance.post(
        this.url + AuthenticationEndpoint + '/register',
        model);

      return {
        success: true,
        message: 'User created'
      }
    } catch (error) {
      if (error.response.status === 400) {
        return {
          success: false,
          message: error.response.data.message
        }
      } else {
        return {
          success: false,
          message: 'Unspecified error'
        }
      }
    }
  }
}

export default new ApiClient()