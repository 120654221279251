import React, { useState } from 'react';
import { SuccessLabel } from '../SuccessLabel';
import { RegisterVM } from '../../viewModels/RegisterVM';
import ApiClient from '../../utils/client/ApiClient';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Box
} from "@chakra-ui/react"
import { Formik, Field, Form } from 'formik';

const RSI = 'https://robertsspaceindustries.com/citizens/'
const RSIshort = 'robertsspaceindustries.com/citizens/'

export function SignUp() {
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const checkPasswordMatch = (password: string, repPassword: string) => {
    if (password !== repPassword) {
      setError('Passwords need to match');
    } else {
      setError('');
    }
  }

  return (
    <Box minWidth='300pt' id='signup-wrapper' marginTop='10pt' border='1pt solid whitesmoke' borderRadius='3pt' padding='5pt'>
      {message === '' ? '' : <SuccessLabel message={message} />}
      <Formik
        initialValues={
          {
            handle: '',
            password: '',
            repPassword: '',
            email: ''
          }
        }
        onSubmit={async (values, actions) => {
          checkPasswordMatch(values.password, values.repPassword);

          let register = new RegisterVM();
          register.CitizenRecord = RSI + values.handle;
          register.Username = values.handle;
          register.Email = values.email;
          register.Password = values.password;

          let result = await ApiClient.SignUp(register);
          if (result.success) {
            setMessage(result.message);
            setError('')
          } else {
            setError(result.message);
            setMessage('');
          }

          actions.setSubmitting(false)
        }}
      >
        {(props) => (
          <Form>
            <Field name='handle'>
              {(field: any, form: any) => (
                <FormControl isRequired>
                  <FormLabel htmlFor='handle'>RSI Handle</FormLabel>
                  <Input id='handle' placeholder='Handle' type='text' onChange={props.handleChange} />
                </FormControl>
              )}
            </Field>
            <div className='signup-username column-display'>
              <label>Link to your profile looks like:</label>
              <label className='bold'>{RSIshort + props.values.handle}</label>
            </div>
            <Field name='email'>
              {(field: any, form: any) => (
                <FormControl isRequired>
                  <FormLabel htmlFor='email'>Your email</FormLabel>
                  <Input id='email' type='email' onChange={props.handleChange} />
                </FormControl>
              )}
            </Field>
            <Field name='password' validate={checkPasswordMatch(props.values.password, props.values.repPassword)}>
              {(field: any, form: any) => (
                <FormControl isRequired>
                  <FormLabel htmlFor='password'>Password</FormLabel>
                  <Input id='password' type='password' onChange={props.handleChange} />
                </FormControl>
              )}
            </Field>
            <Field name='passwordRep' validate={checkPasswordMatch(props.values.password, props.values.repPassword)}>
              {(field: any, form: any) => (
                <FormControl isRequired>
                  <FormLabel htmlFor='passwordRep'>Repeat password</FormLabel>
                  <Input id='passwordRep' type='password' onChange={props.handleChange} />
                </FormControl>
              )}
            </Field>
            <FormErrorMessage>{error}</FormErrorMessage>
            <Button
              mt={4}
              colorScheme="blue"
              type="submit"
              isLoading={props.isSubmitting}
              minWidth='300pt'
            >
              Sign up
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
