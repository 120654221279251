import { Box } from "@chakra-ui/react"
import React from "react"

type SuccessLabelProps = {
  message:string;
}

export function SuccessLabel(props:SuccessLabelProps){
  return (
    <Box minWidth='300pt' textAlign='center' fontFamily='lato' backgroundColor='green.600' borderRadius='3pt'>
      <p>{props.message}</p>
    </Box>
  )
}