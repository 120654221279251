import React, { useState } from "react";
import { SyntheticEvent } from 'react';
import { CargoWrapper } from "../../types/CargoWrapper";
import { AddOnload } from "./AddOnload";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxes } from "@fortawesome/free-solid-svg-icons";
import { ErrorLabel } from "../ErrorLabel";
import { VStack, HStack, Button, Box } from "@chakra-ui/react";

type CargoAdderProps = {
  locationId: number;
  capacity: number;
  cargoUpdater: (cargo: CargoWrapper[]) => void;
  disableSubmit: (isEnabled: boolean) => void;
}

export function CargoAdder(props: CargoAdderProps) {
  const [cargo, setCargo] = useState<CargoWrapper[]>([{
    commodityId: 0,
    commodityLabel: '',
    amount: 0,
    value: 0,
    unitPrice: 0,
    key: 0
  }]);
  const [capacityError, setCapacityError] = useState(false);

  const addCargo = (e: SyntheticEvent) => {
    e.preventDefault();

    let newCargo: CargoWrapper = {
      commodityId: 0,
      commodityLabel: '',
      amount: 0,
      value: 0,
      unitPrice: 0,
      key: cargo.length
    };
    setCargo([...cargo, newCargo]);
    props.cargoUpdater(cargo);
  };

  const updateCargo = (updated: CargoWrapper) => {
    let storedCargo = cargo.find(c => c.key === updated.key);
    let updatedArray = cargo;

    // Validations
    // Check if there is enough room
    let loadedCapacity = 0;
    let alreadyLoaded = cargo.filter(c => c.key !== updated.key)
    if (alreadyLoaded.length !== 0) {
      loadedCapacity = alreadyLoaded
        .map(c => c.amount)
        .reduce((a, b) => a + b);
    }

    if (loadedCapacity + updated.amount > props.capacity * 100) {
      setCapacityError(true);
    } else {
      setCapacityError(false);
    }

    let storedIndex = updatedArray.indexOf(storedCargo!)
    updatedArray[storedIndex] = updated;
    setCargo(updatedArray);
    props.cargoUpdater(cargo);
    canSubmit();
  };

  const removeCargo = (key: number) => {
    setCargo(cargo.filter(c => c.key !== key));
    props.cargoUpdater(cargo);
  }

  const canSubmit = () => {
    if (cargo.find(c => c.unitPrice === 0 || c.value === 0) !== undefined || capacityError) {
      props.disableSubmit(true);
    } else {
      props.disableSubmit(false);
    }
  }

  return (
    <Box id='cargo-adder' width='auto'>
      <VStack borderColor='whitesmoke' borderWidth='1pt' borderRadius='3pt'>
        <HStack>
          <label className='bold'>Total cargo: </label>
          <label>{cargo.reduce((a: number, b: CargoWrapper) => a + b.amount, 0)} Units</label>
        </HStack>
        <HStack>
          <label className='bold'>Total value: </label>
          <label>{cargo.reduce((a: number, b: CargoWrapper) => a + b.value, 0)} aUEC</label>
        </HStack>
      </VStack>
      <Box id='cargo-stacks'>
        <VStack id='onloads'>
          <VStack spacing='2pt' marginTop='5pt'>
            {cargo.find(c => c.unitPrice === 0) !== undefined ? <ErrorLabel message="Unit price can't be zero" /> : ''}
            {cargo.find(c => c.value === 0) !== undefined ? <ErrorLabel message="Total price can't be zero" /> : ''}
            {capacityError ? <ErrorLabel message="You can't load more cargo than the ship can carry" /> : ''}
          </VStack>
          {
            cargo.map(c => <AddOnload
              key={c.key}
              cargo={c}
              updater={updateCargo}
              remover={removeCargo}
              locationId={props.locationId}
            />)
          }
        </VStack>
        <VStack>
          <Button onClick={addCargo} colorScheme='blue' width='300pt' marginTop='5pt'>
            Add cargo<FontAwesomeIcon icon={faBoxes} style={{ paddingLeft: '5' }} />
          </Button>
        </VStack>
      </Box>
    </Box>
  );
}
