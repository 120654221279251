import { OnloadVM } from "../../viewModels/OnloadVM";
import { VStack, HStack } from "@chakra-ui/react";

type OnloadDisplayProps = {
  onload: OnloadVM
}

export function OnloadDisplay(props: OnloadDisplayProps) {
  return (
    <VStack
      align='left'
      spacing='0'
      border='0.5pt solid whitesmoke'
      borderRadius='3pt'
      margin='2pt'
      padding='2pt'>
      <HStack>
        <label className='bold'>Commodity:</label>
        <label>{props.onload.CommodityName}</label>
      </HStack>
      <HStack className='row-display'>
        <label className='bold'>Amount bought:</label>
        <label>{props.onload.Amount + " Units"}</label>
      </HStack>
      <HStack className='row-display'>
        <label className='bold'>Unit price:</label>
        <label>{props.onload.UnitPrice + " aUEC"}</label>
      </HStack>
      <HStack className='row-display'>
        <label className='bold'>Cost:</label>
        <label className='text-red'>{props.onload.Price + " aUEC"}</label>
      </HStack>
    </VStack>
  )
}