import { Box, Flex, Heading, VStack, HStack, SimpleGrid } from "@chakra-ui/react";
import { TradeRunVM } from "../../viewModels/TradeRunVM";
import { OffloadDisplay } from "./OffloadDisplay";
import { OnloadDisplay } from "./OnloadDisplay";

export type CompletedTradeDetailProps = {
  trade: TradeRunVM
}

export function CompletedTradeDetail(props: CompletedTradeDetailProps) {
  return (
    <Box id='completed-trade-detail' bg='#1A202C' marginBottom='5pt' padding='10pt'>
      <Flex>
        <Box borderRight='0.5pt solid whitesmoke' paddingRight='10pt' minWidth="150pt">
          <Heading as='h3' size='md' marginBottom='5pt' marginTop='5pt'>Trade Information</Heading>
          <VStack spacing='0' align='left' width='auto'>
            <HStack>
              <label className='bold'>Trade ID:</label>
              <label>{'#' + props.trade.OwnerId.slice(0, 6).toUpperCase() + '-' + props.trade.Id}</label>
            </HStack>
            <HStack>
              <label className='bold'>Source:</label>
              <label>{props.trade.SourceName}</label>
            </HStack>
            <HStack>
              <label className='bold'>Ship:</label>
              <label>{props.trade.ShipName}</label>
            </HStack>
            <HStack>
              <label className='bold'>Bottomline:</label>
              <label className={props.trade.Bottomline >= 0 ? 'text-green' : 'text-red'}>
                {props.trade.Bottomline + ' aUEC'}
              </label>
            </HStack>
          </VStack>
        </Box>
        <VStack borderRight='0.5pt solid whitesmoke' marginLeft='10pt' marginRight='10pt'>
          <Heading as='h3' size='md' marginTop='5pt' marginBottom='5pt'>Onloads</Heading>
          <SimpleGrid columns={2}>
            {props.trade.Onloads.map(o => <OnloadDisplay onload={o} key={o.Id} />)}
          </SimpleGrid>
        </VStack>
        <VStack borderRight='0.5pt solid whitesmoke' paddingRight='10pt'>
          <Heading as='h3' size='md' marginTop='5pt' marginBottom='5pt'>Offloads</Heading>
          <SimpleGrid columns={2}>
            {props.trade.Offloads.map(o => <OffloadDisplay offload={o} key={o.Id} />)}
          </SimpleGrid>
        </VStack>
        <Box marginLeft='10pt'>
          <Heading as='h3' size='md' marginTop='5pt' marginBottom='5pt'>Notes</Heading>
          {props.trade.Note}
        </Box>
      </Flex>

    </Box >
  )
}