import React from "react";
import { OffloadVM } from "../../viewModels/OffloadVM";
import { HStack, VStack } from "@chakra-ui/react"

type OffloadDisplayProps = {
  offload: OffloadVM;
}

export function OffloadDisplay(props: OffloadDisplayProps) {
  return (
    <VStack align='left' spacing='0' padding='2pt' className='offload-list-item'>
      <HStack>
        <label className='bold'>Commodity:</label>
        <label>{props.offload.CommodityName}</label>
      </HStack>
      <HStack className='row-display'>
        <label className='bold'>Sale location:</label>
        <label>{props.offload.DropOffName}</label>
      </HStack>
      <HStack className='row-display'>
        <label className='bold'>Amount sold:</label>
        <label>{props.offload.Amount + " Units"}</label>
      </HStack>
      <HStack className='row-display'>
        <label className='bold'>Unit price:</label>
        <label>{props.offload.UnitPrice + " aUEC"}</label>
      </HStack>
      <HStack className='row-display'>
        <label className='bold'>Income:</label>
        <label className='text-green'>{props.offload.Price + " aUEC"}</label>
      </HStack>
    </VStack>
  )
}
