import React from 'react';
import { Logout } from './components/auth/Logout';
import { Heading, HStack } from '@chakra-ui/react';
type HeaderProps = {
  token: string;
  username: string;
};

export function Header(props: HeaderProps) {
  return (
    <HStack>
      <Heading as='h1' margin={props.token !== '' ? '10pt' : '20pt'} size={props.token !== '' ? 'lg' : '2xl'}>Trade Tracker</Heading>
      <HStack fontSize='sm' fontFamily='Electrolize'>
        {props.token !== '' ? <label>{`Welcome ${props.username}!`}</label> : ''}
        {props.token !== '' ? <Logout /> : ''}
      </HStack>
    </HStack>
  );
}
